@tailwind base;
@tailwind components;
@tailwind utilities;

html.dark body {
  background-color: rgb(31, 41, 55);
}
#root {
  @apply w-full;
}
body {
  @apply flex  flex-col items-center;
  font-family: "Noto Sans KR", sans-serif;
}
section {
  @apply w-full max-w-screen-2xl;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.toastui-editor-contents img {
  max-height: 500px !important;
}
.popup_text {
  gap: 1vw;
}
h1 {
  font-size: xx-large;
}
h2 {
  font-size: x-large;
}
h3 {
  font-size: larger;
}
h4 {
  font-size: medium;
}
h5 {
  font-size: small;
}
h6 {
  font-size: x-small;
}

#commentList > li:nth-child(n + 1) {
  border-bottom: 1px solid #ddd;
}
#commentList > li:last-child {
  border-bottom: 0;
}

#detailFont,
.toastui-editor-contents *:not(table) {
  font-family: "Gowun Dodum", sans-serif;
}
.ql-container {
  font-size: 18px;
}
html .ql-editor,
.ql-editor .ql-font-serif {
  font-family: "Gowun Dodum", sans-serif !important;
  line-height: 2.2rem;
  min-height: 30vh;
  font-size: 1rem;
}
.ql-editor.viewer {
  line-height: 2.2rem;
}

.subComments li:nth-child(n + 2) {
  @apply border-t-2 border-dashed;
}

html.dark .pagination li {
  color: #333;
  background-color: rgb(156, 163, 175);
  border-radius: 50%;
}
html.dark .pagination li a {
  color: #fff;
}
html.dark ul.pagination li:first-child,
html.dark ul.pagination li:last-child {
  border-radius: 50%;
}
html.dark ul.pagination li.active {
  background-color: #fff;
}
html.dark ul.pagination li.active a {
  color: #333;
}

html.dark .ql-toolbar.ql-snow .ql-formats svg {
  background-color: #fff;
}
/* html.dark .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background-color: #fff;
} */
html.dark .ql-snow .ql-picker {
  background-color: #fff;
}
html.dark .ql-snow .ql-picker-label,
html.dark .ql-snow .ql-picker-options span {
  color: #333;
}
html.dark .ql-editor.viewer {
  color: #fff;
  background-color: rgb(31, 41, 55);
}
html.dark .ql-editor.viewer span {
  color: #fff;
}
html.dark .ql-editor {
  color: #fff;
  background-color: rgb(17, 24, 49);
}
html.dark .ql-toolbar.ql-snow {
  background-color: rgb(43, 48, 65);
}
html.dark .toastui-editor-contents *:not(table) {
  color: #fff;
}

@media (min-width: 640px) {
  *::-webkit-scrollbar {
    width: 7px;
  }
  *::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 7px;
  }
  *::-webkit-scrollbar-track {
    display: none;
  }
}
.menuOpen {
  animation: menuOpen 0.5s;
}

.m_menu {
  width: calc(100vw - 20px);
  height: calc(90vh - 20px);
  animation: menuOpen 0.5s;
}
@keyframes menuOpen {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
